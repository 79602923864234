@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  p {
    @apply font-light;
  }
}
body {
  margin: 0;
  background-color: #f2ecfc;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

th {
  background-color: #e5d9f9;
  color: #8144e5;
}
th,
td {
  white-space: nowrap;
  text-overflow: ellipsis;
}
tr:nth-child(even) {
  background-color: #f2ecfc;
}

/* loader */
.loader {
  width: 108px;
  display: flex;
  justify-content: space-between;
}
.loader::after,
.loader::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #fff;
  background-image: radial-gradient(circle 14px, #0d161b 100%, transparent 0);
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: eyeMove 10s infinite, blink 10s infinite;
}
@keyframes eyeMove {
  0%,
  10% {
    background-position: 0px 0px;
  }
  13%,
  40% {
    background-position: -15px 0px;
  }
  43%,
  70% {
    background-position: 15px 0px;
  }
  73%,
  90% {
    background-position: 0px 15px;
  }
  93%,
  100% {
    background-position: 0px 0px;
  }
}
@keyframes blink {
  0%,
  10%,
  12%,
  20%,
  22%,
  40%,
  42%,
  60%,
  62%,
  70%,
  72%,
  90%,
  92%,
  98%,
  100% {
    height: 48px;
  }
  11%,
  21%,
  41%,
  61%,
  71%,
  91%,
  99% {
    height: 18px;
  }
}

@media screen and (max-width: 1300px) {
  body {
    font-size: 12px;
  }
  .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }
}
